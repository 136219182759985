<template>

	<layout :include_header="true">

		<template v-slot:header-center>

			<main_nav />

		</template>

		<template v-slot:header-end>

			<support_link />

		</template>

		<template v-slot:main-body>

			<div class="w-100 h-100 position-relative overflow-hidden">

				<div class="w-100 h-100 d-flex">

					<div class="d-flex flex-column w-100 h-100">

						<team_nav label="Team brand" />
						
						<div class="w-100 h-100 position-relative py-4 overflow-auto">

							<div class="container h-100">

								<div class="row h-100 justify-content-center">

									<div class="col-12 col-md-10 col-lg-6">

										<loader v-if="team_loading" />

										<div v-else class="card mb-3">						

											<div class="card-body">

												<p v-if="success" class="alert alert-success d-flex align-items-center">
													<span class="material-icons-outlined me-2">check_circle</span>
													Your details were updated successfully
												</p>

												<p v-if="error.message" class="alert alert-danger">
													{{ error.message }}
												</p>

												<form @submit.prevent="submit">

													<form_control
														label="Logo"
														type="file"
														name="company_logo"
														placeholder="Upload Logo"
														:team_id="team.id"
														:required="false"
														:error="error"
														v-model="team.logo"
													/>

													<div v-if="team.logo" class="card w-100 mb-3">
														<div class="img-bg"><img :src="team.logo" height="50" style="max-width: 100%;" /></div>
														<button class="btn btn-link text-danger" v-on:click="[ team.logo = '' ]">
															Remove image
														</button>
													</div>

													<div class="row">
														<div class="col">
															<form_control
																label="Button Background"
																type="color"
																name="company_button_bg"
																:required="false"
																:error="error"
																v-model="team.button_bg"
															/>
														</div>
														<div class="col">
															<form_control
																label="Button Text"
																type="color"
																name="company_button_text"
																:required="false"
																:error="error"
																v-model="team.button_text"
															/>
														</div>
													</div>

													<btn_submit 
														label="Update branding" 
														icon="check"  
														:icon_after="true" 
														:loading="loading" 
													/>

												</form>

											</div>

										</div>

									</div>

								</div>

							</div>

						</div>

					</div>

				</div>

			</div>

		</template>

	</layout>

</template>

<script>
import { mapGetters } from 'vuex';
import layout from '@/components/layout'
import main_nav from '@/components/main_nav'
import team_nav from '@/components/team_nav'
import support_link from '@/components/support_link'
import loader from '@/components/loader'
import form_control from '@/components/form_control'
import btn_submit from '@/components/btn_submit'
import team_service from '@/services/team_service'

export default {
	name: 'team.brand',

	components: {
		layout,
		loader,
		main_nav,
		team_nav,
		support_link,
		form_control,
		btn_submit
	},

	data() {
		return {
			loading: false,
			team_loading: true,
			team: null,
			error: {},
			success: null,
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user'] ),

		...mapGetters( 'countries', ['store_countries'] ),

	},

	mounted()
	{
		this.show()
	},

	methods: {
		reset_error()
		{
			this.error = {}

			this.success = null
		},

		async show()
		{
			this.team_loading = true 

			this.reset_error()

			team_service.show( this.auth_user.team_id ).then(( response ) => {

				this.team = response.data

			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.team_loading = false
			})
		},

		async submit()
		{
			this.loading = true 

			this.reset_error()

			team_service.update( this.auth_user.team_id, this.team ).then(( response ) => {

				this.team = response.data

				this.success = true
			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.loading = false
			})
		},
	}
}
</script>

<style>

</style>
